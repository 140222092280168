import {useState, useRef} from 'react';

function ChatComponent() {


    const [convo, setConvo] = useState("Assistant: Welcome! I am Dr. Gilardino's virtual assistant, here to help you with your post-operative questions. Let's start by telling me what surgery you had.");
    const [newMessage, setNewMessage] = useState("");
    const [threadId, setThreadId] = useState("");
    const [context, setContext] = useState("");
    const [buffering, setBuffering] = useState(false);
    const [uninitiatedId, setUninitiatedId] = useState(`temp*-*-${Date.now()}`);
    const [typingEnabled, setTypingEnabled] = useState(true);

    const scrollableText = useRef();
    const inputTextbox = useRef();

    function submitToServer(message) {
        setTypingEnabled(false);
        setTimeout(()=>{setTypingEnabled(true); 
            setTimeout(()=>{ inputTextbox.current.focus();},100);
        },333);
        setBuffering(true);
        fetch('/api/chat', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ message: message, threadId: threadId == "" ? uninitiatedId : threadId, context: context })
        })
        .then(response => response.json())
        .then(
            data => {
                setBuffering(false);
            addMessage(null,"assistant",data.message);
            setThreadId(data.threadId);
            setContext(data.context);
        })
        .catch(error => console.error('Error:', error));
    }

    function addMessage(e, sender="user", message=null) {
        e != null && e.preventDefault();
        let newValue = "";
        switch(sender) {
            case "user":
                let userMessage = newMessage;
                newValue = `\nYou: ${userMessage}`;
                setNewMessage("");
                submitToServer(userMessage);
                break;
            case "assistant":
                newValue = `\nAssistant: ${message}\n------------------`;
                break;
        }
        setConvo(current=>current + newValue);
        setTimeout(()=>{
            scrollableText.current.scrollTop = scrollableText.current.scrollHeight * 2;
        },333);
    }


    return(

        <div className="container pt-5">
      <div className="mb-3">
        <label htmlFor="chatConvo" className="form-label">Conversation with Dr. G's Virtual Assistant</label>
        <textarea className="form-control" id="chatConvo" rows="20" readOnly="readOnly" value={convo} ref={scrollableText}></textarea>
      </div>
              <div className="mb-3">
        {/* <label htmlFor="chatMessage" className="form-label">Type your new message here</label> */}
        <form onSubmit={(e)=>{addMessage(e);}}>
        <input type="text" className="form-control" id="chatMessage" disabled={!typingEnabled} ref={inputTextbox} placeholder="Type your question here" value={newMessage} onChange={(e)=>setNewMessage(e.target.value)} />
        </form>
      </div>

      <div className="text-end">
        <button className="btn btn-danger" type="submit" onClick={(e)=>{addMessage(e);}}>SEND</button>
      </div>
      <div className="text-center">
      {buffering && <div class="spinner-border text-light me-5" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>}

      </div>
        </div>


        
    );
}
export default ChatComponent;
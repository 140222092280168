import {useState} from 'react';
import ChatComponent from './components/ChatComponent';

function App() {

const [showChat, setShowChat] = useState(false);


  return (
    <div className="App">
      <p className="text-center">
        <button type="button" className="openchat" onClick={()=>{setShowChat(true);}}>OPEN CHAT</button>

      </p>
      { showChat && <div className="chatBackdrop">

        <ChatComponent></ChatComponent>
        



        <div className='light x1'></div>
        <div className='light x2'></div>
        <div className='light x3'></div>
        <div className='light x4'></div>
        <div className='light x5'></div>
        <div className='light x6'></div>
        <div className='light x7'></div>
        <div className='light x8'></div>
        <div className='light x9'></div>
      </div>}
    </div>
  );
}

export default App;
